import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // ログイン
  {
    path: 'login',
    loadChildren: () => import('./pages/t1c-login/t1c-login.module')
      .then(m => m.T1cLoginPageModule)
  },
  // ホーム_ホーム画面
  {
    path: 'entrance',
    loadChildren: () => import('./pages/entrance/entrance.module')
      .then( m => m.EntrancePageModule)
  },
  // ホーム_メニュー画面
  {
    path: 'home',
    loadChildren: () => import('./pages/home-screen/home/home.module')
      .then( m => m.HomePageModule)
  },
  // ホーム（履歴確認）
  {
    path: 'home/confirmHistory',
    loadChildren: () => import('./pages/home-screen/confirm-history/confirm-history.module')
      .then( m => m.ConfirmHistoryPageModule)
  },
  // ホーム（登録情報更新）
  {
    path: 'home/changeInfo',
    loadChildren: () => import('./pages/home-screen/change-regist-info/change-regist-info.module')
      .then( m => m.ChangeRegistInfoPageModule)
  },
  // ホーム_ニックネーム変更画面/ホーム_パスワード変更画面
  {
    path: 'home/changeInfo/UserInfo',
    loadChildren: () => import('./pages/home-screen/change-user-info/change-user-info.module')
      .then( m => m.ChangeUserInfoPageModule)
  },
  // ホーム_自宅最寄乗降地変更画面
  {
    path: 'home/changeInfo/HomeStation',
    loadChildren: () => import('./pages/home-screen/change-home-station/change-home-station.module')
      .then( m => m.ChangeHomeStationPageModule)
  },
  // 降車地選択_選択画面
  {
    path: 'landmark',
    loadChildren: () => import('./pages/select-drop-point/landmark/landmark.module')
      .then( m => m.LandmarkPageModule)
  },
  // 満空情報確認画面
  {
    path: 'vacancy-system',
    loadChildren: () => import('./pages/select-drop-point/vacancy-system/vacancy-system.module')
      .then( m => m.VacancySystemPageModule)
  },
  // 施設の詳細画面
  {
    path: 'vacancy-system-item',
    loadChildren: () => import('./pages/select-drop-point/vacancy-system-item/vacancy-system-item.module')
      .then( m => m.VacancySystemItemPageModule)
  },
  // 降車地選択_他降車地選択画面
  {
    path: 'other-drop-point',
    loadChildren: () => import('./pages/select-drop-point/other-drop-point/other-drop-point.module')
      .then( m => m.OtherDropPointPageModule)
  },
  // 降車地選択_頭文字（行）選択画面
  {
    path: 'initial',
    loadChildren: () => import('./pages/select-drop-point/initial/initial.module')
      .then( m => m.InitialPageModule)
  },
  // 降車地選択＿住所（大字）選択画面
 {
    path: 'address',
    loadChildren: () => import('./pages/select-drop-point/address/address.module')
      .then( m => m.AddressPageModule)
  },
  // 降車地選択＿履歴選択画面
  {
    path: 'history',
    loadChildren: () => import('src/app/pages/select-drop-point/select-history/select-history.module')
      .then( m => m.SelectHistoryPageModule)
  },
  // 降車地選択
  {
    path: 'selectDropPoint',
    loadChildren: () => import('./pages/select-drop-point/select-drop-point/select-drop-point.module')
      .then( m => m.SelectDropPointPageModule)
  },
  // 配車予約_乗降車地確認画面
  {
    path: 'root-confirm',
    loadChildren: () => import('./pages/reserve-vehicle/root-confirm/root-confirm.module').then( m => m.RootConfirmPageModule)
  },
  // 配車予約＿乗車人数選択画面
  {
    path: 'select-number-of-people',
    loadChildren: () => import('./pages/reserve-vehicle/select-number-of-people/select-number-of-people.module')
      .then( m => m.SelectNumberOfPeoplePageModule)
  },
  // 配車予約＿タクシー予約確認画面
  {
    path: 'reserve-confirm',
    loadChildren: () => import('./pages/reserve-vehicle/reserve-confirm/reserve-confirm.module').then( m => m.ReserveConfirmPageModule)
  },
  //配車予約_料金詳細画面
  {
    path: 'fee-details',
    loadChildren: () => import('./pages/reserve-vehicle/fee-details/fee-details.module').then( m => m.FeeDetailsPageModule)
  },
  {
    path: 'booking-confirmed',
    loadChildren: () => import('./pages/ride-on-vehicle/booking-confirmed/booking-confirmed.module')
      .then( m => m.BookingConfirmedPageModule)
  },
  //乗車前＿車両到着画面
  {
    path: 'ride-on',
    loadChildren: () => import('./pages/ride-on-vehicle/ride-on/ride-on.module')
      .then( m => m.RideOnPageModule)
  },
  // 発車
  {
    path: 'take-off-vehicle',
    loadChildren: () => import('./pages/ride-on-vehicle/take-off-vehicle/take-off-vehicle.module')
      .then( m => m.TakeOffVehiclePageModule)
  },
  // 乗車中
  {
    path: 'on-boad',
    loadChildren: () => import('./pages/ride-on-vehicle/on-boad/on-boad.module')
      .then( m => m.OnBoadPageModule)
    },
  // 乗車地選択
  {
    path: 'select-meeting-point',
    loadChildren: () => import('./pages/select-meeting-point/select-meeting-point/select-meeting-point.module')
      .then( m => m.SelectPuNearPointPageModule)
  },
  // タクシー予約時のタイムアウト
  {
    path: 'reserve-time-out',
    loadChildren: () => import('./pages/reserve-time-out/reserve-time-out.module').then( m => m.ReserveTimeOutPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules ,useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
